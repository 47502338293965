import 'bootstrap';
import 'jquery';
global.$ = global.jQuery = jQuery;

import { ScrollTracker } from '@wallbrinkcrossmedia/wcm-es6-scroll-tracker/src/js/ScrollTracker';

import { Loader } from '@googlemaps/js-api-loader';
global.googleMapsLoader = Loader;

const loadedComponents = [];

document.addEventListener( 'DOMContentLoaded', async () => {

	// Scroll tracker
	const scrollTracker = new ScrollTracker('js-scroll-tracker', {
		background: '#CA2127',
		height: '2px',
		events: {
			beforeDraw: () => {},
			afterDraw: () => {},
			beforeCalculate: () => {},
			afterCalculate: () => {}
		}
	})

	/**
	 * Bootstrap the components
	 */
	for ( const dataComponent of document.querySelectorAll( '[data-component]' ) ) {
		let componentName = dataComponent.dataset.component;

		if ( loadedComponents.indexOf( componentName ) === - 1 ) {
			loadedComponents.push( componentName );

			if (componentName !== 'contenteditor::onSave') {

				try {
					let component = await import(
						/* WebpackMode: "lazy" */
						/* webpackPrefetch: true */
						/* webpackPreload: true */
						`./${componentName}.js`
						);

					component.default();
				} catch (error) {
					console.error('Loading error: ', error);
				}
			}
		}
	}
} );
